.reset-done {
  width: 100%;
  max-width: 392px !important;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  font-weight: 400;
}

.forgotPassword-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.forgotPassword-container {
  width: 100%;
  max-width: 392px !important;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.forgotPassword-logo {
  width: 272px;
  height: 72px;
  margin-bottom: 25px;
}

.login-logo{
  width: 272px;
  height:72px;
  margin-bottom: 60px;
  cursor: pointer;
}

.font-for-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
}


.reset-successfully {
  margin-top:60px
}

.error-text{
  font-family: 'Poppins';
  font-weight: 400;
  color: red !important;
}