.login-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-text{
    font-family: 'Poppins';
    
    font-weight: 400;
    color: red !important;
}

.login-container {
  width: 100%;
  max-width: 392px !important;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.login-logo{
    width: 272px;
    height:72px;
    margin-bottom: 60px;
 }

 .resetPassword-img{
     width: 100%;
 }

.login-title { 
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
}

.password-reset {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.login-forgot-password{ 
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-decoration: none;
    color: black;
    position: absolute;
    right: 0rem;
    top: 0rem;
}

.password-reset p {
    margin:0;
}

.login-password-input-toggle {
    left: 90%;
    position: absolute;
    right: 0px;
    bottom: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 40px;
}

.input-group label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.login-btn button {
    width:100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding-top: 12px;
    padding-bottom: 12px;
}


.login {
    height: 100%;
    cursor: pointer;
}

.font-for-login-page { 
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    }

 .black {
     color: black;
 }   

 .red {
     color: red;
 }

 .hide-icon { 
     opacity: 0.5;
     cursor: default!important;
}


.checkbox {
    font-family: "Poppins"!important;;
    font-style: normal!important;;
    font-weight: 500!important;
    font-size: 14px!important;
    line-height: 21px!important;
    
}
.checkbox .checkmark{
    background-color: #fffafa;
}