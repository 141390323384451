button {
    &:disabled {
      opacity: .3 !important;
    }
  }
  .btn {
    padding: 1.5rem;
    font-family: 'Poppins', sans-serif;
    padding-top: 0.66rem !important;
    padding-bottom: 0.66rem !important;
    border: none;
    outline: none;
    border-radius: 0;
    font-size: .75rem;
    transition: .3s all ease;
    font-weight: 400;
    background: #F5F8FA;
    color: #000;
    border: 1px solid transparent;
    border-radius: 2rem;
    text-decoration: none;
    cursor: pointer;
    &:disabled {
      opacity: .3 !important;
      cursor: not-allowed;
    }
    &.btn-square {
      border-radius: 0 !important;
    }
    &.btn-icon-only {
      background: #F3F6F8;
      padding: 0 !important;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.2rem;
      height: 1.2rem;
      min-width: 1.2rem;
      min-height: 1.2rem;
      &.icon-big {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
    &.btn-clear {
      background: transparent;
      color: #000;
    }
    &.btn-large {
      padding: .75rem 1.8rem;
      // border-radius: 1em;
    }
    &.btn-block {
      padding: .75rem 1.8rem;
      width: 100%;
      // border-radius: 1em;
    }
    &:active {
      opacity: .7;
    }
    &.btn-outline {
      color: #000;
      background: #FFF;
      border-color: #000;
    }
    &.btn-orange {
      background: #F28705;
      color: #FFF;
    }
    &.btn-orange-outline {
      background: #FFF;
      border-color: #F28705;
      color: #F28705;
    }
    &.btn-orange-light {
      background: #FFEEDA;
      color: #F28705;
    }
    &.btn-danger-light {
      color: #C50532;
      background: #F9E5EA;
    }

    &.btn-green-light {
      background: #18BD85;
      color: #FFFFFF;
    }

    &.btn-blue {
      background: #002E63;
      color: #FFF;
    }
    &.btn-blue-outline {
      color: #002E63;
      background: #FFF;
      border-color: #002E63;
    }
    &.btn-blue-light {
      background: #42ACDE;
      color: #FFF;
    }
    &.btn-blue-light-outline {
      background: white;
      border: 1px solid #42ACDE;
      color: #42ACDE;
      font-weight: bolder;

      // &:hover {
      //   background: #42ACDE;
      //   color: #FFF;
      // }
    }
    &.btn-shy-light {
      color: #42ACDE;
      background: #DDEBF2;
    }
    &.btn-danger {
      background: #C50532;
      color: #FFF;
    }
    &.btn-danger-outline {
      color: #C50532;;
      background: #FFF;
      border-color: #C50532;
    }

    &.btn-gray-light {
      background: #F3F6F8;
      color: #6C6C6C;
    }
    &.btn-bg-blue-light {
      background: #CEE8F4;
      color: #000;
    }
    &.btn-skyblue {
      background-color: #42ACDE;
      color:#FFFFFF;
    }
  }
