/* DISPLAYS */

.d-flex { display: flex !important; }
.d-inline-flex { display: inline-flex !important; }
.d-block { display: block !important; }
.d-inline-block { display: inline-block !important; }
.d-none { display: none !important; }
.img-object-fit-cover {object-fit: cover;}

/* MARGINS / PADDINGS */
.tab-1 {tab-size: 1;}
.tab-2 {tab-size: 2;}



.m-0 { margin:0!important; }
.m-1 { margin:1rem!important; }
.m-1-5 { margin:1.5rem!important; }
.m-2 { margin:2rem!important; }
.m-2-5 {margin: 2.5rem !important;}
.m-3 { margin:3rem!important; }
.m-4 { margin:4rem!important; }
.m-5 { margin:5rem!important; }

.mt-0 { margin-top:0!important; }
.mr-0 { margin-right:0!important; }
.mb-0 { margin-bottom:0!important; }
.ml-0 { margin-left:0!important; }
.mx-0 { margin-left:0!important;margin-right:0!important; }
.my-0 { margin-top:0!important;margin-bottom:0!important; }


.mt-0-5 { margin-top: .5rem !important; }
.mb-0-5 { margin-bottom: .5rem !important; }
.mr-0-5 { margin-right:.5rem!important; }

.mt-1 { margin-top:1rem!important; }
.mr-1 { margin-right:1rem!important; }
.mb-1 { margin-bottom:1rem!important; }
.ml-1 { margin-left:1rem!important; }
.mx-1 { margin-left:1rem!important;margin-right:1rem!important; }
.my-1 { margin-top:1rem!important;margin-bottom:1rem!important; }

.mt-1-5 { margin-top:1.5rem!important; }
.mr-1-5 { margin-right:1.5rem!important; }
.mb-1-5 { margin-bottom:1.5rem!important; }
.ml-1-5 { margin-left:1.5rem!important; }
.mx-1-5 { margin-left:1.5rem!important;margin-right:1.5rem!important; }
.my-1-5 { margin-top:1.5rem!important;margin-bottom:1.5rem!important; }

.mt-2 { margin-top:2rem!important; }
.mt-2-5 {margin-top: 2.5rem !important;}
.mr-2 { margin-right:2rem!important; }
.mr-2-5 {margin-right: 2.5rem !important;}
.mb-2 { margin-bottom:2rem!important;}
.mb-2-5 {margin-bottom: 2.5rem !important;}
.ml-2 { margin-left:2rem!important; }
.ml-2-5 {margin-left: 2.5rem !important;}
.mx-2 { margin-right:2rem!important;margin-left:2rem!important; }
.mx-2-5 {margin-left: 2.5rem !important; margin-right: 2.5rem !important;}
.my-2 { margin-top:2rem!important;margin-bottom:2rem!important; }
.my-2-5 {margin-top: 2.5rem !important;margin-bottom: 2.5rem !important;}

.mt-3 { margin-top:3rem!important; }
.mr-3 { margin-right:3rem!important; }
.mb-3 { margin-bottom:3rem!important; }
.ml-3 { margin-left:3rem!important; }
.mx-3 { margin-right:3rem!important;margin-left:3rem!important; }
.my-3 { margin-bottom:3rem!important;margin-top:3rem!important; }

.mt-4 { margin-top:4rem!important; }
.mr-4 { margin-right:4rem!important; }
.mb-4 { margin-bottom:4rem!important; }
.ml-4 { margin-left:4rem!important; }
.mx-4 { margin-right:4rem!important;margin-left:4rem!important; }
.my-4 { margin-top:4rem!important;margin-bottom:4rem!important; }

.mt-5 { margin-top:5rem!important; }
.mr-5 { margin-right:5rem!important; }
.mb-5 { margin-bottom:5rem!important; }
.ml-5 { margin-left:5rem!important; }
.mx-5 { margin-right:5rem!important;margin-left:5rem!important; }
.my-5 { margin-top:5rem!important;margin-bottom:5rem!important; }

.mt-auto { margin-top:auto!important; }
.mr-auto { margin-right:auto!important; }
.mb-auto { margin-bottom:auto!important; }
.ml-auto { margin-left:auto!important; }
.mx-auto { margin-right:auto!important;margin-left:auto!important; }
.my-auto { margin-bottom:auto!important;margin-top:auto!important; }

.p-0 { padding:0!important; }
.p-1 { padding:1rem!important; }
.p-1-5 { padding:1.5rem!important; }
.p-2 { padding:2rem!important; }
.p-2-5 {padding: 2.5rem !important;}
.p-3 { padding:3rem!important; }
.p-4 { padding:4rem!important; }
.p-5 { padding:5rem!important; }

.pt-0 { padding-top:0!important; }
.pr-0 { padding-right:0!important; }
.pb-0 { padding-bottom:0!important; }
.pl-0 { padding-left:0!important; }                             
.px-0 { padding-left:0!important;padding-right:0!important; }
.py-0 { padding-top:0!important;padding-bottom:0!important; }

.pt-1-5 { padding-top:1.5rem !important; }
.pr-1-5 { padding-right:1.5rem !important; }
.pb-1-5 { padding-bottom:1.5rem !important; }
.pl-1-5 { padding-left:1.5rem !important; }                             
.px-1-5 { padding-left:1.5rem !important;padding-right:1.5rem !important; }
.py-1-5 { padding-top:1.5rem !important;padding-bottom:1.5rem !important; }

.pt-1 { padding-top:1rem!important; }         
.pr-1 { padding-right:1rem!important; }                       
.pb-1 { padding-bottom:1rem!important; }      
.pl-1 { padding-left:1rem!important; }                            
.px-1 { padding-left:1rem!important;padding-right:1rem!important; }
.py-1 { padding-top:1rem!important;padding-bottom:1rem!important; }

.pt-2 { padding-top:2rem!important; }                                              
.pr-2 { padding-right:2rem!important; }                                
.pb-2 { padding-bottom:2rem!important; }               
.pl-2 { padding-left:2rem!important; }                                             
.px-2 { padding-right:2rem!important;padding-left:2rem!important; }
.py-2 { padding-top:2rem!important;padding-bottom:2rem!important; }

.pt-3 { padding-top:3rem!important; }                               
.pr-3 { padding-right:3rem!important; }             
.pb-3 { padding-bottom:3rem!important; }                
.pl-3 { padding-left:3rem!important; }                              
.py-3 { padding-bottom:3rem!important;padding-top:3rem!important; }
.px-3 { padding-right:3rem!important;padding-left:3rem!important; }

.pt-4 { padding-top:4rem!important; }                             
.pr-4 { padding-right:4rem!important; }               
.pb-4 { padding-bottom:4rem!important; }              
.pl-4 { padding-left:4rem!important; }                                
.px-4 { padding-right:4rem!important;padding-left:4rem!important; }
.py-4 { padding-top:4rem!important;padding-bottom:4rem!important; }

.pt-5 { padding-top:5rem!important; }   
.pr-5 { padding-right:5rem!important; } 
.pb-5 { padding-bottom:5rem!important; }    
.pl-5 { padding-left:5rem!important; }  
.px-5 { padding-right:5rem!important;padding-left:5rem!important; }
.py-5 { padding-top:5rem!important;padding-bottom:5rem!important; }



// TEXTS
.tiny-text {
    font-size: calc(1rem - 8px);
    font-weight: 500;
  }
  
  .small-text {
    font-size: calc(1rem - 4px);
    font-weight: 500;
  }
  
  .regular-text {
    font-size: calc(1rem - 2px);
    font-weight: 600;
  }
  
  .subtitle-text {
    font-size: calc(1rem - 3px);
    font-weight: 600;
    color: #AAA;
    text-transform: uppercase;
  }
  
  .title-text {
    font-size: calc(1rem);
    font-weight: 600;
  }
  
  .big-text {
    font-size: calc(1rem + 6px);
    font-weight: 600;
  }


// TEXT ALIGN
.text-left { text-align: left !important; }
.text-center { text-align: center !important; }
.text-right { text-align: right !important; }


// CONTAINERS
.selected{
  //border-left: 1px solid #0262cf !important;
  border: 3px solid #0262cf !important;
}

.small-container {
    max-width: 800px;
    width: 90%;
    margin: 0 auto;
}

.bg-transparent {
  background: transparent !important;
}

.bg-white {
  background-image: none !important;
  background: #FFF !important;
}

.bg-light-blue {
  background-color: #CEE8F4 !important;
}

.no-border {
  border: none !important;
}


.text-orange {
  color: #F28705 !important;
}


.text-white {
  color: #fff !important;
}

.text-red {
  color: #C50532 !important;
}

.text-green {
  color: #74C336 !important;
}

.text-green-dark {
  color: #4a7a24 !important;
}

.text-blue {
  color: #002E63 !important;
}

.text-blue-light {
  color: #42ACDE !important;
}


.text-gray {
  color: #9FB8CC !important;;
}
.text-gray-dark {
  color: #AAAAAA !important;
}

.text-light-blue {
  color: #42ACDE !important;
}


.w-25 {
  width: 25% !important;
}

.w-35{
  width: 35% !important;
}

.w-100 {
  width: 100% !important;
}

@keyframes spin
{
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s forwards infinite linear;
}


.sticky-bottom {
  position: sticky;
  bottom: 0;
  background: #FFF;
  z-index: 10;
}

.sticky-top {
  position: sticky;
  top: 0;
  background: #FFF;
  z-index: 1;
}

.border-radius {
  border-radius: 8px;
}

.border-top {
  border-top: 1px solid #c6c6c6;
}

.border-bottom {
  border-bottom: 1px solid #c6c6c6;
}

.border-bottom-2 {
  border-bottom: 2px solid #c6c6c6;
}

.border-light-blue {
  border-color: #42ACDE;
}

.border-left {
  border-left: 1px solid #c6c6c6;
}

.border-left-2 {
  border-left: 2px solid #c6c6c6;
}

.cursor-pointer {
  cursor: pointer !important;
}

.no-select {
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Old versions of Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.bg-gray {
  background-color: #F3F6F8 !important;
}

.bg-gray-dark {
  background: #FAFAFA !important;
}

.bg-light {
  background-color: #F8F8F8 !important;
}

.bg-red {
  background-color: #C50532 !important;
}

.bg-red-light {
  background-color: #F9E5EA !important;
}

.bg-green-light {
  background-color: #E5F9EA !important;
}

.bg-gold {
  background: #ffe6a0 !important;
}
.bg-blue {
  background: #ecedf7 !important;
}

.bg-blue-light {
  background: #42ACDE !important;
}

.bg-light-blue {
  background-color: #CEE8F4 !important;
}

.border {
  border: 1px solid #CCC;
}

.h-70 {
  height: 70vh;
}

.position-relative { position: relative !important;}
.position-absolute { position: absolute !important;}

.max-content {
  width: max-content !important;
}

.m-auto{
  margin: auto !important;
}

.rounded {
  border-radius: 8px;
}

.no-radius {
  border-radius: 0 !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

//IMAGE

.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}


.no-wrap{
  flex-wrap: nowrap;
}


.overflow-item-dashboard{
  overflow:auto;
  scrollbar-width:none;
    &::-webkit-scrollbar { 
      display: none; 
    }

}

.scroll-dashboard{
  overflow-y: hidden;
  scrollbar-width:none;
    &::-webkit-scrollbar { 
      display: none; 
    }
}

.v-hidden{
  visibility: hidden;
}



@media (max-width:992px) {
  .scroll-dashboard{
    overflow:auto;
    scrollbar-width:initial;
      &::-webkit-scrollbar { 
        display: block; 
      }
  }

}

.w-50 {
  width: 50% !important;
}


.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-auto::-webkit-scrollbar {
  display: none;
}

.btn-decode {
  right: 20px;
  bottom: 30px;
  position: absolute;
}
