
.z-index-1 { z-index: 1 !important; }
.z-index-5 { z-index: 5 !important; }
.z-index-10 { z-index: 9 !important; }
.z-index-100 { z-index: 99 !important; }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 8px;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.0rem;
  border-radius: 8px;
}

.vehicle-card{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #F3F6F8;
  border-radius: 0.7rem;
}

.vehicle-card-selected{
  background-color: #CEE8F4 !important;
}

.vehicle-inner-card{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 0.7rem;
}
