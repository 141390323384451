.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 99999999999999999 !important;
}

.modal-title {
  color: #FFFFFF;
  padding: 15px 10px;
  font-size: 0.750rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-content {
  width: 55vw;
  height: 100vh;
  background-color: #fff;
}

.modal-rounded {
  border-radius: 12px;
}

.modal-scroll {
  height: 90vh;
  overflow-y: auto;
}

.modal-scroll::-webkit-scrollbar {
  display: none;
}

.content-center {
  width: 35vw;
  height: auto !important;
}

.modal-footer {
  border-top: 1px solid #eee;
}

.modal-header {
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin: 0;
}

.modal-header, .modal-body, .modal-footer {
  padding: 1rem;
  display: flex;
}

.modal-body {
  height: inherit;
  overflow-y: auto;
  flex-wrap: wrap;
}

.modal-body::-webkit-scrollbar {
  display: none;
}
