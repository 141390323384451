.invoice-section {
  width: 90vw;
  height: 100%;
}

.invoce-tabs {
  background-color: #ffffff;
  border-radius: 25px;
}

.table-tabs {
  padding: 25px 0 25px 0;
}

.selected {
  border: 3px solid #18bd85 !important;
}

.invoice-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
