.form-group {
  &.cursor-pointer {
    input,
    select,
    textarea {
      cursor: pointer !important;
    }
  }
    margin-bottom: .8rem;
    ::placeholder {
      color: #9FB8CC !important;
      // font-size: 0.5rem;

    }
    .placeholder {
      color: #9FB8CC !important;
      font-size: .6rem !important;
    }
    .hint-text {
      margin: .25rem 0 0 .5rem;
      color: #9FB8CC;
      font-size: 0.55rem;
      transition: .3s all ease;

      font-weight: 600;
      white-space: break-spaces;
      &.error-text {
        color: #FF0033;
      }
    }
    label {
      font-size: .6rem;
      user-select: none;
      font-weight: 400;
      margin-bottom: calc(1rem - 8px);
      display: block;
      &.checkbox {
        display: inline-block;
        margin-bottom: .3rem;
        cursor: pointer;
        // margin: 0 !important;
      }
      &.error-label {
        color: #FF0033;
      }
    }
    select.form-field,
    input.form-field[type='text'],
    input.form-field[type='email'],
    input.form-field[type='number'],
    input.form-field[type='tel'],
    input.form-field[type='password'],
    textarea.form-field {
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      background: #F3F6F8;
      border-radius: .5rem;
      width: 100%;
      padding: .4rem .5rem;
      border: 1px solid #F3F6F8;

      &:disabled {
        opacity: .5 !important;
      }

      &.error-field {
        color: #FF0033;
        border-color: #FF0033;
        &:focus {
          border-color: #FF0033;
        }

      }
      &:focus {
          border-color: #42ACDE;
      }
      outline: none;
      transition: .3s all ease;
      font-size: .6rem;
      // position: relative;
      font-family: 'Source Sans Pro', sans-serif;

    }
    input.form-field[type='checkbox'] {
      display: inline-block;
      margin: 0;
      margin-right: .5rem;
      cursor: pointer
    }
    textarea {
      min-height: 152px;
      max-height: 152px;
      resize: none;
    }
    select {
      background: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E) center right .5rem no-repeat #F4F4F4 !important;
      background-position: right .5rem 0 bottom !important;
      background-size: .5rem !important;
      // background-position-x: right 100px !important;
      cursor: pointer;
      &.error-field {
        background: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FF0033%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E) center right .5rem no-repeat #F4F4F4 !important;
        background-position: right .5rem 0 bottom !important;
        background-size: .8rem !important;
      }

    }
  }

  .container {
    display: block;
    position: relative;
    // padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  // input{
  //   cursor: pointer ;
  // }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 16px;
    width: 16px;
    background-color: #ccc;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    right: 3px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

textarea {
  resize: none;
}

.dollar-sign {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='7' y='13' fill='gray' font-size='12' font-family='arial'>$</text></svg>");
  background-repeat: no-repeat;
  background-position: left;
}


.label-style {

  font-size: .6rem;
  font-weight: normal;

  margin-bottom: calc(1rem - 8px);
}