.font-6 { font-size: 0.375rem !important; }
.font-8 { font-size: 0.500rem !important; }
.font-9 { font-size: 0.5625rem !important; }
.font-10 { font-size: 0.625rem !important; }
.font-11 { font-size: 0.7rem !important; }
.font-12 { font-size: 0.750rem !important; }
.font-14 { font-size: 0.875rem !important; }
.font-16 { font-size: 1em !important; }
.font-18 { font-size: 1.125rem !important; }
.font-20 { font-size: 1.250rem !important; }
.font-24 { font-size: 1.5rem !important; }

.font-light { font-weight: 300; }
.font-medium { font-weight: 600; }
.font-normal { font-weight: 400; }
.font-italic { font-style: italic !important;}
.font-bold { font-weight: bold !important; }
.font-medium-i { font-weight: 600 !important; }


.fs-9{font-size: 0.45rem !important; }
.fs-10{font-size: 0.5rem !important; }
.fs-11{font-size: 0.55rem !important; }
.fs-12{font-size: 0.6rem !important;}
.fs-14{font-size: 0.7rem !important;}
.fs-16{font-size: 0.8rem !important;}
.fs-18{font-size: 0.9rem !important;}
.fs-40{font-size: 2.5rem !important;}

.font-mediun-500{font-weight: 500;}


.line-height-0-6 {
    line-height: 0.6rem !important;
}