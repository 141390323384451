.toggle-container {
  /* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 14px;
  margin: 0 !important;

  label {
    margin: 0 !important;
    padding: 0 !important;
    font-size: .45rem !important;
  }
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 0px;
  bottom: 2px;
  transform: translateX(2px);
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: rgb(66, 172, 222);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(66, 172, 222);
}

input:checked + .slider:before {
  
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

}