/** badges **/

.badge-rounded {
    color: white;

    font-size: 0.7rem;
    font-weight: normal;

    padding: 8px 14px;
    border-radius: 20px;
    opacity: 1;

    transition: 0.2s ease-in;
  }

  .badge {
    color: #FFF;
    font-weight: 600;
    background: #000;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    font-size: 0.4rem;
    // font-weight: normal;

    white-space: nowrap;
    letter-spacing: 1px;

    padding: .2rem .3rem;
    border-radius: 4px;
    opacity: 1;
    display: inline-flex;
    align-items: center;
    user-select: unset;
    cursor: pointer;

    transition: 0.2s ease-in;
    &.selected-status {
      border: 4px solid #F0A926;
    }
    &.no-selected-status {
      border: 4px solid transparent;
    }
  }

  .badge-cancelled, .badge-not-paid, .badge-late, .badge-past-due, .badge-not-completed, .badge-incompleted, .badge-change-request, .badge-expired { background-color: #C50532; }
  .badge-paid, .badge-accepted { background-color: #FDF1E2; color: #FF8000 !important; }
  .badge-in-progress, .badge-queued-for-capture, .badge-pending { background-color: #6BCA71; color: #FFFFFF !important; }
  .badge-not-picked-up, .badge-started { background-color: #eeefd4; color: #9a9819 !important; }
  .badge-picked-up { background-color: #D5EFD4; color: #199A49 !important; }
  .badge-ready, .badge-finished, .badge-quoted, .badge-completed, .badge-delivered { background-color: #D5EFD4; color: #199A49 !important; }
  .badge-received, .badge-traffic-on { background-color: #198D48; color: #FFFFFF !important; }
  .badge-invoiced, .badge-non-storage, .badge-non-inventory, .badge-no-move,  .badge-non-move { background-color: #F9B6AC; color: #FFFFFF !important; }
  .badge-due-soon, .badge-needs-invoice { background-color: #FB7268; color: #FFF; }
  
  .badge-not-started, .badge-booked,  .badge-needs-quote { background-color: #FF6AA2; color: #FFF !important; }
  .badge-dispatched, .badge-outgoing { background-color: #9F00FF !important; color: #FFF; }
  .badge-picked-up, .badge-incoming, .badge-parked, .badge-needs-finish-services { background-color: #46C5F3 !important; color: #FFF !important; }
  .badge-in-transit { background-color: #EB4886 !important; color: #FFF !important; }
  .badge-active {background-color: #46C5F3; color: #FFF;}
  .badge-inactive, .badge-traffic-off {background-color: #C50532; color: #FFF;}
  .badge-no-fail {
    background: #ff0000;
    color: #FFF;
  }