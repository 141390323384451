.flex-wrap { flex-wrap: wrap !important; }
.flex-column { display: flex; flex-direction: column !important; }
.flex-nowrap {flex-wrap: nowrap !important;}

.justify-content-normal { justify-content: normal !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-evenly { justify-content: space-evenly !important; }
.justify-content-around { justify-content: space-around !important; }
.justify-content-start { justify-content: flex-start !important; }
.justify-content-end { justify-content: flex-end !important; }
.justify-content-center { justify-content: center !important; }


.align-items-baseline { align-items: baseline !important; }
.align-items-center { align-items: center !important; }
.align-items-stretch { align-items: stretch !important; }
.align-items-end { align-items: flex-end !important; }
.align-items-start { align-items: flex-start !important; }

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  
}

// COL
  .col-0-5 {
    width: calc(4.16% - .5rem);
  }

  .col-1 {
      width: calc(8.33% - .5rem);
    }
  
  .col-2 {
    width: calc(16.66% - .5rem);
  }
  
  .col-3 {
    width: calc(25% - .5rem);
  }

  .col-3-5 {
    width: calc(29% - .5rem);
  }
  
  .col-4 {
    width: calc(33.33% - .5rem);
  }
  
  .col-5 {
    width: calc(41.16% - .5rem);
  }
  
  .col-6 {
    width: calc(50% - .5rem);
  }
  
  
  .col-7 {
    width: calc(58.33% - .5rem);
  }
  
  .col-8 {
    width: calc(66.66% - .5rem);
  }
  
  .col-9 {
    width: calc(75% - .5rem);
  }
  
  .col-10 {
    width: calc(83.33% - .5rem);
  }
  
  .col-11 {
    width: calc(91.66% - .5rem);
  }

  .col-11-5 {
    width: calc(95.82% - .5rem)
  }
  
  
  .col-12 {
    width: 100% !important;
  }

  .max-flex{
    height: calc(100% - 0px);
    width: calc(100% - 0px);
  }

  .w-max-content {
    width: max-content !important;
  }
  
  
  @media screen and (max-width: 767px)
  {
    .col-sm-12 {
      width: 100% !important;
    }
    .col-sm-6 {
      width: calc(50% - .5rem) !important;
    }
    .mt-sm-2 {
      margin-top: .5rem !important;
    }
    .mt-sm-3 {
      margin-top: 1rem !important;
    }
    .height-sm-auto {
      height: auto !important;
    }
    .overflow-sm-visible {
      overflow: visible !important;
    }
  }


  @media screen and (max-width: 790px)
  {
    .col-md-12 {
      width: 100% !important;
    }
    .col-md-11-5 {
      width: calc(95.82% - .5rem)
    }
      
    .col-md-11 {
      width: calc(91.66% - .5rem);
    }

    .justify-content-md-center { justify-content: center !important; }

  }



  @media screen and (max-width: 992px) {
    .col-lg-12 {
      width: 100% !important;
    }

    .col-lg-11-5 {
      width: calc(95.82% - .5rem)!important;
    }

    .col-lg-11 {
      width: calc(91.66% - .5rem)!important;
    }

    .col-lg-6 {
      width: calc(50% - .5rem) !important;
    }

    .col-lg-4 {
      width: calc(33.33% - .5rem)!important;
    }
  }
