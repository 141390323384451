.main {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width:100%;
    overflow: hidden;
}


.canvas{
    width: 100%;
    max-width:1024px !important;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.route-body{
    overflow-x: hidden;
    overflow-y: scroll;
}

.route-body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

.navbar-user-logout{
    position: absolute;
    z-index: 999;
    top: 40px;
    right: 8px;
    width: 184px;
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    &:log-oute-text{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
}