.new-charges-container {
    max-width: 1024px !important;
    width: 100% !important;
}


.new-charges-prices, .new-charges-items{ 
    flex-basis: 45%;
    align-items: center;
    display: flex;
    justify-content: center;
}


 .new-charges {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
 }

.new-charges-prices { 
    margin-top: 160px;
}

.new-charges-items { 
    margin-top: 313px;
}